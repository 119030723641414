<template>
  <div class="Appdownload">
    <div class="bigImg">
      <div class="download-btn" @click="scrollToElement">立即下载</div>
    </div>
    <div class="amTitle" ref="targetElement2">
      <p @click="changeModel(1)">
        <span :class="control == 1 ? 'clickSpan' : '' ">APP下载</span>
        <img v-if="control == 1" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(2)">
        <span :class="control == 2 ? 'clickSpan' : '' ">MT4下载</span>
        <img v-if="control == 2" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(3)">
        <span :class="control == 3 ? 'clickSpan' : '' ">MT5下载</span>
        <img v-if="control == 3" src="../../static/index/amRed.png" alt="">
      </p>
    </div>
    <div class="aCenter" v-show="control === 1">
      <div class="iconBox">
        <div class="ibsBox">
          <div>
            <img src="../../static/appDownload/icon1.png" alt="">
            <span>丰富的行情资讯</span>
          </div>
          <div>
            <img src="../../static/appDownload/icon2.png" alt="">
            <span>精准的产品报价</span>
          </div>
          <div>
            <img src="../../static/appDownload/icon3.png" alt="">
            <span>精彩的广场互动</span>
          </div>
          <div>
            <img src="../../static/appDownload/icon4.png" alt="">
            <span>智能止盈止损</span>
          </div>
          <div>
            <img src="../../static/appDownload/icon5.png" alt="">
            <span>丰富的新手活动</span>
          </div>
          <div>
            <img src="../../static/appDownload/icon6.png" alt="">
            <span>超低点差，免费注册</span>
          </div>
        </div>
      </div>
      <div class="aBottom">
        <ul>
          <p>即刻下载 掌控您的财富先机</p>
          <li>
            <img src="../../static/index/ad1.png" alt="">
            <span>顶尖技术团队倾力打造</span>
          </li>
          <li>
            <img src="../../static/index/ad2.png" alt="">
            <span>2000+日夜精心打磨APP细节</span>
          </li>
          <li>
            <img src="../../static/index/ad3.png" alt="">
            <span>200+万用户使用</span>
          </li>
          <li>
            <img src="../../static/index/ad4.png" alt="">
            <span>权威平台认可</span>
          </li>
        </ul>
        <img class="acenterImg" src="../../static/index/ad5.png" alt="" ref="targetElement1">
        <div class="uldiv2">
          <div class="uldiv2-title">下载MT4账户专用版本</div>
          <div>
              <a :href="iosMt4DownloadUrl">
              <img src="../../static/index/btn_ios.png" alt="">
              </a>
              
          </div>
          <div>
            <a @click="handleDownload(androidMt4DownloadUrl)">
              <img src="../../static/index/btn_android.png" alt="">
              </a>
          </div>
        </div>
        <div class="uldiv2">
          <div class="uldiv2-title">下载MT5账户专用版本</div>
          <div>
              <a :href="iosMt5DownloadUrl">
              <img src="../../static/index/btn_ios.png" alt="">
              </a>
              
          </div>
          <div>
            <a @click="handleDownload(androidDownloadUrl)">
              <img src="../../static/index/btn_android.png" alt="">
              </a>
          </div>
        </div>
      </div>
      <div class="uldiv">
        <div class="uldiv-box">
          <div class="uldiv-box-title">
            下载MT4账户专用版本
          </div>
          <div class="uldiv-box-code">
            <p v-if="showIos">
              <span>
                <!-- <img src="../../static/index/iosCode.png" alt=""> -->
                <Qrcode :link="iosMt4DownloadUrl" :size="180" :iconSize="30"/>
              </span>
              <span>IOS</span>
            </p>
            <p>
              <span>
                <Qrcode :link="androidMt4DownloadUrl" :size="170"/>
                <!-- <img :src="SPuRl" key="urlKey" alt=""> -->
              </span>
              <span>安卓下载</span>
            </p>
          </div>
        </div>
        <div class="uldiv-box">
          <div class="uldiv-box-title uldiv-box-title-two">
            下载MT5账户专用版本
          </div>
          <div class="uldiv-box-code">
            <p v-if="showIos">
              <span>
                <Qrcode :link="iosMt5DownloadUrl" :size="180" :iconSize="30"/>
              </span>
              <span>IOS</span>
            </p>
            <p>
              <span>
                <Qrcode :link="androidDownloadUrl" :size="170" />
              </span>
              <span>安卓下载</span>
            </p>
          </div>
        </div>
      </div>
      <div class="warm-tips">
        <div>
          <div class="warm-tips-title">温馨提示：</div>
          <div>
            1.MT4版本，仅MT4账户可用；MT5版本，仅MT5账户可用，两者不互通。 <br>
            2.本平台2024年8月31日10：00之前开立的账户，均为MT4账户。之后开立的账户均为MT5账户。如果不确定，请联系在线客服查询。
          </div>
        </div>
        <div class="warm-tips-service" @click="handleService">
          <img src="../../static/common/service.png" alt="">
          <div>联系客服</div>
        </div>
      </div>
    </div>
    <div v-show="control === 2" class="mCenter">
      <img class="mt4Img" src="../../static/index/mt4Img.png" alt="">
      <ul>
        <li>MT4下载</li>
        <li>
          <span>
            MT4是一款综合了行情图表、技术分析、交易操作三大功能于一身，可进行下单、平仓限价单、止损、止盈、查看报表及分析数据等不同操作的强大功能软件，是目前非常广泛使用的行情软件之一。
            <br>
            目前在全球有超过300家经纪商及银行正在使用MT4平台，是全球最受欢迎的在线交易平台。
          </span>
          <span>
            
          </span>
        </li>
        <li class="mtPcDownload">
          <p>
            <span class="span1 banner" @click="Appstoreload('MT4')">
              <img src="../../static/common/ios_code.png" alt="">
            </span>
            <span>IOS</span>
          </p>
          <p>
            <span class="span2 banner" @click="Androidload('MT4')">
              <img src="../../static/common/android_code.png" alt="">
            </span>
            <span>Android</span>
          </p>
          <p @click="windowsDownload('MT4')">
            <span class="span3 banner2">
              <!-- <img src="../../static/common/windows_code.png" alt=""> -->
            </span>
            <span>Windows</span>
          </p>
        </li>
        <li class="mtWapDownload">
          <img class="mt4Img" src="../../static/index/mt4Img.png" alt="">
          <div @click="Appstoreload('MT4')">
            <img src="../../static/index/btn_ios.png" width="230" height="53" alt="">
          </div>
          <div @click="Androidload('MT4')">
            <img src="../../static/index/btn_android.png" width="230" height="53" alt="">
          </div>
        </li>
      </ul>
    </div>
    <div v-show="control === 3" class="mCenter">
      <img class="mt5Img" src="../../static/index/mt5Img.png" alt="">
      <!-- <img class="mt4Img" src="../../static/index/mt4Img.png" alt=""> -->
      <ul>
        <li>MT5下载</li>
        <li>
          <span>
            MT5是一个功能强大且专业的金融交易软件，支持外汇、股票、期货等多种金融产品交易。提供了丰富的图表分析工具、技术指标和自动化交易功能，帮助交易者更有效地分析市场趋势和实现交易自动化。MT5更适合专业交易者和有经验的投资者。
          </span>
        </li>
        <li class="mtPcDownload">
          <p>
            <span class="span1 banner" @click="Appstoreload('MT5')">
              <img src="../../static/common/ios_code_mt5.png" alt="">
            </span>
            <span>IOS</span>
          </p>
          <p>
            <span class="span2 banner" @click="Androidload('MT5')">
              <img src="../../static/common/android_code_mt5.png" alt="">
            </span>
            <span>Android</span>
          </p>
          <p @click="windowsDownload('MT5')">
            <span class="span3 banner2">
              <!-- <img src="../../static/common/windows_code.png" alt=""> -->
            </span>
            <span>Windows</span>
          </p>
        </li>
        <li class="mtWapDownload">
          <img class="mt5Img" src="../../static/index/mt5Img.png" alt="">
          <div @click="Appstoreload('MT5')">
            <img src="../../static/index/btn_ios.png" width="230" height="53" alt="">
          </div>
          <div @click="Androidload('MT5')">
            <img src="../../static/index/btn_android.png" width="230" height="53" alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mapState  } from "vuex";
  import Qrcode from './QrCode/index.vue'

  export default {
    name: "Appdownload",
    components: {
      Qrcode,
    },
    metaInfo: {  
      title: '红狮金业APP,现货黄金白银投资专业软件,伦敦金伦敦银靠谱平台,黄金交易红狮启富APP',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易软件,贵金属投资APP,贵金属平台,现货黄金开户,现货白银,正规靠谱平台。' },  
        { name: 'description', content: '红狮金业APP是一款稳定安全的交易软件，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
    data() {
      return {
        qrCodeDataUrl: '',
        SPuRl:require('../../static/index/androidcode.png'),
        showIos:true,
        control:1,
      }
    },
    computed: {
      ...mapState('common', {
        androidMt4DownloadUrl: 'androidMt4DownloadUrl',
        androidDownloadUrl: 'androidDownloadUrl',
        iosMt4DownloadUrl: 'iosMt4DownloadUrl',
        iosMt5DownloadUrl: 'iosMt5DownloadUrl',
      })
    },
    created(){
      const Rhref = window.location.host;
      if(Rhref.indexOf('rlchltd')>-1){
        // this.showIos = false
      }else{
        this.showIos = true
      }
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
      // 通过上个页面传来的参数进行control的控制；
      if(this.$route.query.key !== undefined){
        this.control = Number(this.$route.query.key);
      }

    },
    methods:{
      //获取Android下载链接
      async getAndroidUrl(code) {
        const res = await getChannelInfo(code)
        const { downloadUrl } = res.Data;
        this.APKurl = downloadUrl || '';
        const { origin } = window.location;
        const absoluteURLRegex = /^[a-zA-Z]+:\/\/.+/;
        if (absoluteURLRegex.test(downloadUrl)) {
          this.APKurl = downloadUrl
        } else {
          this.APKurl = origin + '/' + downloadUrl
        }
        const icon = require('../../public/hongshi.jpg');
        generateQRCode(this.APKurl, this.$refs.qrcodeCanvas, icon)
        generateQRCode(this.APKurl, this.$refs.qrcodeCanvas2, icon)
      },
      changeModel(key){
        this.control = key;
      },
      //pc设备
      windowsDownload(type){
        const userAgent = navigator.userAgent;
        console.log(userAgent);
        let deviceType = '';
        if (/android/i.test(userAgent)) {
          // 判断为安卓设备
          deviceType = 'Android';
          this.$message(`当前设备为: ${deviceType},请下载对应App`);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // 判断为苹果iOS设备，同时排除Windows Phone等可能包含'iPad'、'iPhone'、'iPod'字样的设备
          deviceType = 'iOS';
          this.$message(`当前设备为: ${deviceType},请下载对应App`);
        } else {
          // 其他设备，如桌面设备等
          deviceType = 'Other';
          if(type === 'MT5') {
            window.open('https://download.mql5.com/cdn/web/hong.kong.red/mt5/hkredlioncapital5setup.exe');
          } else {
            window.open('https://download.mql5.com/cdn/web/hong.kong.red/mt4/hkredlioncapital4setup.exe');
          }
          
        }
      },
      //ios设备下载
      Appstoreload(type) {
        const userAgent = navigator.userAgent;
        console.log(userAgent);
        let deviceType = '';
        const downloadUrl = type === 'MT4' ? 'https://apps.apple.com/cn/app/metatrader-4/id496212596' : 'https://download.mql5.com/cdn/mobile/mt5/ios?server=HKRedLionCapital-Server';
        if (/android/i.test(userAgent)) {
          // 判断为安卓设备
          deviceType = 'Android';
          this.$message(`当前设备为: ${deviceType},请下载对应App`);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // 判断为苹果iOS设备，同时排除Windows Phone等可能包含'iPad'、'iPhone'、'iPod'字样的设备
          deviceType = 'iOS';
          window.open(downloadUrl);
        } else {
          // 其他设备，如桌面设备等
          deviceType = 'Other';
          window.open(downloadUrl);
        }
      },
      //安卓设备下载
      Androidload(type) {
        const userAgent = navigator.userAgent;
        console.log(userAgent);
        let deviceType = '';
        const downloadUrl = type === 'MT4' ? 'https://files.metaquotes.net/metaquotes.software.corp/mt4/metatrader4.apk' : 'https://download.mql5.com/cdn/mobile/mt5/android?server=HKRedLionCapital-Server';
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
        if(isAndroid && window.android) {
          window.android.onOpenUrl(downloadUrl);
        } else if (/android/i.test(userAgent)) {
          // 判断为安卓设备
          deviceType = 'Android';
          window.open(downloadUrl);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // 判断为苹果iOS设备，同时排除Windows Phone等可能包含'iPad'、'iPhone'、'iPod'字样的设备
          deviceType = 'iOS';
          this.$message(`当前设备为: ${deviceType},请下载对应App`);
        } else {
          // 其他设备，如桌面设备等
          deviceType = 'Other';
          window.open(downloadUrl);
        }
      },
      handleDownload(link) {
        const userAgent = navigator.userAgent;
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
        if(isAndroid && window.android) {
          window.android.onOpenUrl(link);
        } else {
          window.open(link);
        }
      },
      // 联系客服
      handleService() {
        const url = sessionStorage.getItem('liveUC')
        window.open(url)
      },
      scrollToElement() {
        const element = this.$refs['targetElement' + (this.control === 1 ? 1 : 2)];
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
    // 通过监听上个页面传来的参数进行control的控制；
    watch: {
      $route(nv){
        console.log(nv.query.key);
        this.control = nv.query.key;
      }
    },
  }
</script>

<style lang="less" scoped>
  .Appdownload{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bigImg{
      width: 100%;
      height: 600px;
      background: url('../../static/appDownload/banner.png') no-repeat;
      background-size: cover;
      position: relative;
      .download-btn {
        position: absolute;
        top: 390px;
        left: 258px;
        color: #fff;
        background: #e90014;
        width: 455px;
        line-height: 80px;
        border-radius: 12px;
        font-size: 26px;
        cursor: pointer;
      }
    }
    .amTitle{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 80px;
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span{
          color: #666666;
          font-size: 30px;
          letter-spacing: 2px;
          margin-bottom: 30px;
        }
        .clickSpan{
          color: #000000;
        }
      }
      p:nth-child(2){
        margin: 0 90px;
      }
    }
    .aCenter{
      width: 100%;
      .iconBox{
        width: 100%;
        box-sizing: border-box;
        padding: 80px 20px;
        // background: url('../../static/appDownload/gnBanner.png') no-repeat;
        // background-size: cover;
        .ibsBox{
          width: 1224px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          div{
            width: 28%;
            height: 230px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 50px;
            box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
            img{
              width: 80px;
              height: 80px;
              margin-bottom: 10px;
            }
            span{
              font-size: 18px;
              font-weight: 600;
              line-height: 40px;
              margin-bottom: 40px;
            }
          }
          div:nth-child(1) img{
            width: 70px;
            height: auto;
          }
          div:nth-child(6) img{
            width: 60px;
          }
          // div:hover {
          //   transition: transform 0.35s ease;
          //   background-color: #e1251a;
          // }
          // div:hover span{
          //   color: #fff;
          // }
        }
      }
      .aBottom{
        width: 1300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        padding-left: 36px;
        // padding-bottom: 100px;
        ul{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          p{
            font-size: 36px;
            color: #333333;
            margin-bottom: 55px;
          }
          li{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 55px;
            span{
              color: #333134;
              margin-left: 20px;
              font-size: 22px;
            }
          }
           
          
        }
        .acenterImg {
          width: 48%;
          position: relative;
          top: -20px;
          // left: -50px;
        }
        .uldiv2{
          display: none;
        }
      }
      .uldiv{
        width: 1224px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
        &-box {
          width: 50%;
          box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
          &-title {
            padding: 16px 0;
            font-size: 20px;
            background: linear-gradient(to right, #eaf1ff, #f5f4fb);
          }
          &-title-two {
            background: linear-gradient(to right, #f5f4fb, #fef6f7);
          }
          &-code {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px 0;
          }
          p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span:nth-child(1){
              width: 240px;
              height: 240px;
              background: url('../../static/index/mt4ios.png') no-repeat;
              background-size: cover;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .canvasCode{
              display: none;
            }
            span{
              color: #333333;
              font-size: 18px;
              margin-top: -10px;
            }
            &:hover {
              span:nth-child(1){
                background: url('../../static/index/juxingBanner.png') no-repeat;
                background-size: cover;
              }
              .canvasCode {
                display: block;
              }
            }
          }
          p:nth-child(2){
            span:nth-child(1){
              width: 240px;
              height: 240px;
              background: url('../../static/index/mt4android.png') no-repeat;
              background-size: cover;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .canvasCode {
              display: none;
            }
            &:hover {
              span:nth-child(1){
                background: url('../../static/index/juxingBanner.png') no-repeat;
                background-size: cover;
              }
              .canvasCode {
                display: block;
              }
            }
          }
        }
      }
      .warm-tips {
        text-align: left;
        display: flex;
        width: 1224px;
        margin: 60px auto 80px;
        align-items: center;
        background: #f8f8f8;
        border-radius: 8px;
        padding: 40px 70px;
        box-sizing: border-box;
        line-height: 24px;
        justify-content: space-between;
        &-title {
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 10px;
        }
        &-service {
          flex-shrink: 0;
          text-align: center;
          background: #fff;
          font-size: 20px;
          padding: 20px;
          border-radius: 8px;
          margin-left: 160px;
          cursor: pointer;
          img {
            margin-bottom: 8px;
          }
        }
      }
    }
    .mCenter{
      width: 1213px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin: 80px auto 150px;
      .mt4Img{
        width: 650px;
        height: auto;
        margin-right: 70px;
        margin-top: 50px;
      }
      .mt5Img{
        width: auto;
        height: 440px;
        margin: 50px 90px 0 120px;
      }
      ul{
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: center;
        li{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
        li:nth-child(1){
          font-size: 36px;
          color: #343434;
        }
        li:nth-child(2){
          flex-direction: column;
          font-size: 18px;
          color: #333230;
          line-height: 30px;
          margin: 10px 0;
          span{
            text-align: justify;
          }
        }
        .mtPcDownload{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: -20px;
          p{
            z-index: 999;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
              width: 120px;
              height: auto;
              display: none;
            }
            span{
              font-size: 14px;
              color: #989a99;
              margin-top: 15px;
            }
            .banner{
              width: 170px;
              height: 170px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .banner2{
              width: 170px;
              height: 170px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .span1{
              background: url('../../static/index/mt4ios.png') no-repeat;
              background-size: cover;
            }
            .span2{
              background: url('../../static/index/mt4android.png') no-repeat;
              background-size: cover;
            }
            .span3{
              background: url('../../static/index/mt4win.png') no-repeat;
              background-size: cover;
            }
          }
          p:hover .banner{
            background: url('../../static/index/juxingBanner.png') no-repeat;
            background-size: 170px 170px;
          }
          p:hover img{
            display: block;
          }
        }
        .mtWapDownload {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .Appdownload{
      width: 100%;
      .bigImg{
        height: 450px;
        background: url('../../static/index/wapImg/downloadBanner.jpg') no-repeat;
        background-size: cover;
        .download-btn {
          position: absolute;
          width: 62%;
          top: 254px;
          left: 19%;
          color: #fff;
          background: #e90014;
          line-height: 40px;
          border-radius: 6px;
          font-size: 14px;
        }
      }
      .amTitle{
        margin-top: 30px;
        p{
          span{
            font-size: 18px;
            letter-spacing: 0;
            margin-bottom: 10px;
          }
          img{
            height: 2px;
          }
        }
        p:nth-child(2){
          margin: 0 30px;
        }
      }
      .aCenter{
        .iconBox{
          padding:0;
          padding-top: 30px;
          .ibsBox{
            width: 95%;
            margin: 0 auto;
            div{
              width: 45%;
              margin-bottom: 20px;
              height: auto;
              box-sizing: border-box;
              padding: 25px 0 10px 0;
              img{
                width: 40px;
                height: auto;
              }
              span{
                font-size: 14px;
                margin-bottom: 0px;
              }
            }
            div:nth-child(1){
              img{
                width: 43px;
                height: auto;
              }
            }
            div:nth-child(6){
              img{
                width: 35px;
                height: auto;
              }
            }
          }
        }
        .aBottom{
          width: 100%;
          flex-direction: column;
          padding: 0px;
          padding-bottom: 10px;
          ul{
            width: 80%;
            margin: 0 auto;
            justify-content: flex-start;
            align-items: flex-start;
            p{
              font-size: 22px;
              margin: 20px auto 20px;
            }
            li{
              box-sizing: border-box;
              margin-bottom: 15px;
              img{
                width: 45px;
              }
              span{
                font-size: 15px;
              }
            }
             
            
          }
          .acenterImg{
            width: 80%;
            height: auto;
            margin: 30px auto 0;
            top: 0;
            left: 0;
          }
          .uldiv2{
            display: block;
            width: 90%;
            margin: 25px auto 0;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
            padding-bottom: 10px;
            &-title {
              background: linear-gradient(to right, #eaf1ff, #fef5f7);
              padding: 11px 0;
              margin-bottom: 25px;
            }
            a{
              display: block;
              margin: 0 auto 10px;
              img{
                width: 200px;
              }
            }
          }
        }
        .uldiv{
          display: none;
        }
        .warm-tips {
          width: 90%;
          padding: 20px 16px;
          margin: 30px auto 30px;
          font-size: 13px;
          line-height: 18px;
          &-title {
            font-size: 14px;
            margin-bottom: 6px;
          }
          &-service {
            margin-left: 16px;
            font-size: 13px;
            padding: 8px;
            img {
              width: 24px;
              margin-bottom: 4px;
            }
          }
        }
      }
      .mCenter{
        width: 100%;
        flex-direction: column;
        padding-bottom: 0px;
        margin: 30px auto 50px;
        .mt4Img, .mt5Img{
          display: none;
        }
        ul{
          width: 80%;
          margin: 30px auto 0;
          li:nth-child(1){
            font-size: 24px;
          }
          li:nth-child(2){
            font-size: 14px;
            margin: 20px auto;
          }
          .mtPcDownload{
            display: none;
          }
          .mtWapDownload {
            display: block;
            margin-bottom: 20px;
            text-align: center;
            .mt4Img, .mt5Img{
              display: block;
              width: 100% !important;
              height: auto;
              margin: 40px auto;
            }
            div {
              display: block;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  
</style>